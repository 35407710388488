<template>
  <div>
    <template v-for="(detail, i) in fields">
      <template v-if="i > 0"> | </template>
      <EntityDetail
        :key="`detail_${i}`"
        v-model="value"
        :context="context"
        :attributes="detail"
      ></EntityDetail>
    </template>
  </div>
</template>
<script>
import EntityDetail from "./EntityDetail.vue";
const debug = require('debug')('atman.component.entity_detail')  // eslint-disable-line
export default {
  name: "EntityDetails",
  props: {
    attributes: {},
    value: {},
    context: {},
  },
  computed: {
    fields() {
      return this.attributes?.details?.fields || [];
    },
  },
  components: {
    EntityDetail,
  },
  mounted() {
    debug(`EntityDetails`, this.$props);
  },
};
</script>
