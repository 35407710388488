<template>
  <span class="text--secondary" v-if="details"
    >{{ attributes.label }}
    <span class="clickable font-weight-bold text-subtitle-1">{{
      details
    }}</span>
  </span>
</template>
<script>
const debug = require('debug')('atman.component.entity.detail')  // eslint-disable-line

export default {
  name: "EntityDetail",
  props: {
    value: {},
    label: {},
    attributes: {},
    context: {},
  },
  data() {
    return {
      details: null,
    };
  },
  mounted() {
    const component = this;
    debug(`value`, this.value, "attributes", this.attributes);
    component.fetchDetails();
  },
  methods: {
    async makeServerCall(id) {
      const component = this;
      const url = component?.attributes?.api?.url;
      const payload = {
        url: `${url}/${id}`,
        type: "get",
      };
      debug(`payload`, payload);
      try {
        const response = await this.$store.dispatch(
          `${this.context}/makeServerCall`,
          payload
        );
        debug(`response`, response);
        return (
          response?.data?.[component?.attributes?.display_node || "name"] || ""
        );
      } catch (e) {
        debug(`error`, e);
      }
    },
    async fetchDetails() {
      const component = this;
      const url = component?.attributes?.api?.url;
      const inBookData = this.value[component.attributes.node];
      if (!url) {
        debug(`no api available for detail`, this.attributes);
        this.details = inBookData;
        return;
      }
      if (Array.isArray(inBookData)) {
        const promises = [];
        for (let i = 0; i < inBookData.length; i++) {
          promises.push(await this.makeServerCall(inBookData[i]));
        }
        Promise.all(promises).then((results) => {
          debug(`results`, results);
          component.details = results.join(", ");
        });
      } else {
        try {
          const response = await this.makeServerCall(inBookData);
          component.details =
            response?.data?.[component?.attributes?.display_node || "name"] ||
            "";
        } catch (e) {
          debug(`error`, e);
        }
      }
    },
  },
};
</script>
